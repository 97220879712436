.el-divider.el-divider--horizontal {
  margin: 16px 0;
}
.el-divider {
  background-color: #f5f5f5;
  position: relative;
}
.sjzStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sjzStyle .listLabel {
  width: 90x;
  text-align: right;
  padding-left: 15px;
}
.sjzStyle .listInput {
  width: 300px;
  margin: 0 10px;
}










































































































































































































































































































//分割线
.el-divider.el-divider--horizontal {
  margin: 16px 0;
}
.el-divider {
  background-color: #f5f5f5;
  position: relative;
}
.sjzStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  .listLabel {
    width: 90x;
    text-align: right;
    padding-left: 15px;
  }
  .listInput {
    width: 300px;
    margin: 0 10px;
  }
}
